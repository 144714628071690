/* first section  */
.contact-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contact-container :last-child {
  margin-bottom: 2rem;
}

.title-contact {
  max-width: 30rem;
  font-size: x-large;
  color: #2b88d9;
}

.para-contact-container {
  text-align: center;
}

.para-contact-first {
  font-size: 1.5rem;
}

.para-contact-first:after {
  content: "";
  display: block;
  max-width: 550px;
  margin: 1rem auto;
  border-bottom: 1px solid #293845;
}

.para-contact-second {
  font-size: 1.2rem;
  color: #293845;
}

/* second section - formulaire */
/* .form-box {
  margin: 3rem;
} */

.checkbox-form {
  max-width: 36rem;
  margin: 2rem auto;
}

input[type="text" i],
input[type="email" i],
textarea {
  width: 30rem;
  margin: 1rem;
  padding: 10px 20px;
  color: #7d7979;
}

.choose-contact {
  width: 32.75rem;
  margin: 1rem;
  padding: 10px 20px;
  color: #7d7979;
}

.accept-condition {
  margin: 1rem;
}

textarea {
  height: 7rem;
  resize: none;
}

.btn-contact {
  display: block;
  margin: 2rem auto;
  background-color: #fff;
  border: 1px solid #384957;
  border-radius: 5px;
  padding: 1.5rem 6.5rem;
  cursor: pointer;
  color: #384957;
}

/* thirth section */
.container-others-contact {
  max-width: 1000px;
  margin: 0 auto;
}

.others-contact-title {
  font-size: 1.5rem;
  text-align: center;
  color: #1bae9f;
}

.others-contact-title:after {
  content: "";
  display: block;
  max-width: 300px;
  margin: 0.5rem auto;
  border-bottom: 1px solid #293845;
}

.others-contact {
  display: flex;
  justify-content: space-evenly;
  margin: 2.5rem;
}

.others-contact-link {
  display: flex;
  align-items: center;
  margin: 1rem;
}

.icon-sociaux-contact {
  width: 3rem;
}

/* Mobile ( if the responsive is on the top it not work ! */
@media only screen and (max-width: 650px) {
  /* first container - contact page  */
  .container-main-contact {
    margin-left: auto;
    margin-right: auto;
    width: 20rem;
  }

  .contact-container {
    display: block;
  }

  .contact-container > figure > img {
    width: 250px;
  }

  .title-contact {
    font-size: large;
  }

  .first-child:after {
    content: "";
    display: block;
    max-width: 650px;
    margin: 1.5rem auto;
    border-bottom: 1px solid #293845;
  }

  .first-param:after {
    content: "";
    display: block;
    max-width: 550px;
    margin: 1rem auto;
    border-bottom: 1px solid #293845;
  }

  .para-contact-first {
    font-size: 1.3rem;
  }

  .para-contact-second {
    font-size: 1.1rem;
  }

  /* thirth section responsive  */
  .others-contact {
    margin: 2rem;
  }

  /* form container - contact page  */
  .checkbox-form {
    max-width: 19rem;
    margin: 0 auto;
  }

  input[type="text" i],
  input[type="email" i],
  textarea {
    width: 15rem;
    padding: 10px 20px;
  }

  textarea {
    height: 7rem;
  }

  .choose-contact {
    width: 17.6rem;
  }

  .others-contact {
    max-width: 400px;
    margin: 0 auto;
  }

  .btn-contact {
    display: block;
    margin: 1rem auto;
    background-color: #fff;
    border: 1px solid #384957;
    border-radius: 5px;
    padding: 1rem 2.5rem;
    cursor: pointer;
    color: #384957;
  }

  /* footer contact page  */
  .container {
    display: block;
    padding: 2rem;
  }

  .container > figure {
    padding: 0.5rem;
    margin: 0;
  }

  .container > figure > img {
    width: 100px;
  }
}
